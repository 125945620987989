import React from "react";
import { Heading,Box,Text,Flex } from "@chakra-ui/react";
import Link from '../components/gatsbylink'
import { StaticImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import MeinSeo from '../components/seo'
const myVariants={
  hidden: {opacity:0,width:"0vw",height:200},
  visible: {opacity:1,
   width:"100vw",
   maxHeight:200,
   overflow:'hidden',
  transition: {duration:2}}

}

export default function Frenkel(){
 
  return(
    <motion.div 
    initial={{opacity:0,paddingBottom:"100px"}}
    animate={{
      opacity:1
    }}
    transition={{duration:.1,delay:.1,ease:"circInOut"}}
    >   
<Flex pt="60px" flexWrap="wrap" flexDirection={["column","column","row"]}>
<MeinSeo title="Das Schicksal der jüdischen Familie Frenkel aus Oberwesel" description="Die jüdische Famile"/>
<Box pr="3" flex="2">
  <Link color="blue" to="/oberwesel"><Text>&rarr; back</Text></Link>
 <Heading  variant="utitel">Oberwesel, Kirchstraße 91: Wilhelm, Emma und Ruth Frenkel</Heading>
 <Text variant="solid">
 In der Kirchstraße 91 (früher 25) wohnte bis 1928 der Metzger Bernhard Mayer mit seiner Familie. Seine Tochter Emma (*1881) heiratete 1906 Wilhelm Frenkel (*1878) aus Sien bei Idar-Oberstein. Willis Schwester Clementine Frenkel (*1876) war mit Bernhards Sohn Jakob Mayer verheiratet, Metzger in der Rathausstraße 4. Im Haus Kirchstraße 89 (früher 23) eröffnete Willi zunächst eine Bäckerei, übernahm aber 1926 die Metzgerei seines Schwiegervaters Bernhard Mayer. Emma und Willi Frenkel adoptierten 1920 die in Berlin geborene Edith Feder (*1918) und gaben ihr den Namen Ruth Frenkel. Willis Bruder Jakob Frenkel (*1872), Kaufmann in Frankenthal in der Pfalz, war 1918 gefallen, sein Name steht auf der Gefallenentafel auf dem Oberweseler Friedhof.
 </Text>
 <Heading variant="utitel">29. Mai 1937</Heading>
 <Text variant="solid">Die 19-jährige Ruth Frenkel, in Berlin geboren am 27.01.1918 als Edith Helga Feder, Adoptivtochter von Emma und Willi Frenkel, wird polizeilich abgemeldet. Sie war nach der Schule als Haushaltshilfe in Frankfurt in Stellung. Ruth fährt am 02.06.1937 mit der »Manhattan« von Hamburg nach New York. Dort heiratet sie bald Kurt Schoemann (*1908), er stammt aus Trarbach. Sie bekommen zwei Kinder: Steven (*1944) und Eileen (*1945). Von 1943 bis 1945 dient Kurt Schoemann als Soldat in der US-Army. Tochter Eileen heiratet Robert Astrin, Eileen stirbt am 16.02.1996. Kurt Schoemann stirbt am 10.12.1962 in New York</Text>
 <Heading variant="utitel">30. April 1938</Heading>
 <Text variant="solid">Willi Frenkel, geboren am 08.09.1878 in Sien, und seine Frau Emma, geb. Mayer, geboren am 24.01.1881 in Oberwesel, müssen ihre Metzgerei gewerbepolizeilich abmelden. Sie müssen ihr Haus an einen Schiffer verkaufen, können aber weiter dort wohnen bleiben.
</Text>
<Heading variant="utitel">1941</Heading>
<Text variant="solid">Nachdem sie fast die gesamte Einrichtung verkauft haben, müssen sie zu Moritz Mayer in die Liebfrauenstraße 50 ziehen, das zum »Judenhaus« erklärt worden ist. Hier haben sie nur ein Zimmer, da Moritz Mayer und seine Schwester Jenny auch ihren Bruder Leo und seine Frau Ida aus Planig aufgenommen haben.</Text>
<Heading variant="utitel">2. April 1942</Heading>
<Text variant="solid">
Emma und Willi Frenkel werden zusammen mit Mayers und anderen Juden von zwei Polizisten mit Handgepäck vom Rathaus aus durch die Unterstraße zum Bahnhof getrieben und nach Bad Salzig in das marode Hotel Schwan in der Bopparder Straße 13 transportiert.
</Text>
<Heading variant="utitel">
30. April 1942
</Heading>
<Text variant="solid">
Von Bad Salzig werden sie zum Güterbahnhof Koblenz-Lützel verfrachtet und in gedeckte Güterwagen verladen. Der Zug kommt am 03.05.1942 in Krasnystaw an. Diese im Distrikt Lublin und in der Nähe von Izbica gelegene Kreisstadt ist die Bahnstation für das Durchgangsghetto Krasniczyn. Dorthin müssen die Juden zu Fuß laufen. Einen Monat später, im Juni 1942, wird das Ghetto liquidiert.
</Text>
<Heading variant="utitel">
21. Dezember 1962
</Heading>
<Text variant="solid">
Ruth Schoemann, Alleinerbin von Emma und Willi Frenkel, wird, »da ein Nachweis für den Anschaffungspreis nicht erbracht wurde«, der Schaden an Mobiliar und Hausrat geschätzt auf »abgerundet RM 635,--«, wofür eine Entschädigung »umgerechnet im Verhältnis 10:2« in Höhe von DM 127,-- gewährt wird.
</Text>
<Heading variant="utitel">
1. Dezember 1983
</Heading>
<Text variant="solid">
Ruth Schoemann stirbt in einem Altenpflegeheim in Cincinnati.
</Text>

</Box>

<Box mt="16" pl="3" flex="1 1">

  <Flex flexWrap="wrap">
   
<Box p="1" w={["100%","33%","100%","100%"]}>
  <motion.div 
     variants={myVariants}>
    <StaticImage src="../images/oberwesel/03.jpg" alt="Emma Frenkel"  />
    <Text variant="Bild">Emma Frenkel</Text>
   </motion.div>
  </Box>
  <Box p="1" w={["100%","33%","100%","100%"]}>
  <motion.div 
     variants={myVariants}>
    <StaticImage src="../images/oberwesel/05.jpg" alt="Wilheim Frenkel"  />
    <Text variant="Bild">Wilheim Frenkel</Text>
   </motion.div>


   
  </Box>
  <Box p="1"  w={["100%","33%","100%","100%"]}>
  <motion.div 
     variants={myVariants}>
    <StaticImage src="../images/solo/frenkelhaus.jpeg" alt="Haus Frenkel"  />
    <Text variant="Bild">Haus Frenkel in der Kirchgasse</Text>
   </motion.div>
   
  </Box>

  </Flex>

</Box>
</Flex>
</motion.div>
  )
}



